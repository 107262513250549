import { consume } from '@lit/context';
import { Task } from '@lit/task';
import { HttpClient } from 'aurelia-fetch-client';
import { clientContext } from 'context/client-context';
import { SortOrder } from 'controllers/storage-order-controller';
import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { baseStyles, flexStyles, gridStyles } from 'pli';
import { TMSResponse } from 'response-types/base-response';
import { getFormattedLocalTime } from 'utils/datetime-formatter';
import { nameFormatter } from 'utils/name-formatter';
import { useTotalPagesCount } from 'utils/use-total-pages-count';

import '../../pli/pli-card';
import '../../pli/pli-search-form';
import '../../pli/pli-skeleton';
import '../../pli/pli-text';
import '../../pli/pli-status-label';
import '../../pli/pli-table';
import '../../pli/pli-pagination';
import '../../pli/pli-user-bubble-list';
import { defineHeaderItems } from '../../pli/pli-table';
import '../../pli/pli-illustration';
import { ReportFilingStatus } from './schema';

type ReportFilingsResponse = TMSResponse<ReportFiling>;

type ReportFiling = {
    reportFilingId: string;
    filingName: string;
    reportCode: string;
    createdUtc: Date;
    partyFirstName: string;
    partyLastName: string;
    status: ReportFilingStatus;
    assigned: Assigned[];
};

type Assigned = {
    userId: string;
    firstName: string;
    lastName: string;
    fullName: string;
};

type QueryPayload = {
    page: number;
    field: string | null;
    order: string | null;
};

@customElement('report-filings-overview')
class ReportFilingsOverview extends LitElement {
    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    public client: HttpClient;

    static styles = [
        baseStyles,
        gridStyles,
        flexStyles,
        css`
            .gap-05 {
                gap: var(--size-0-5);
            }

            .to-case-button {
                --negative-offset: calc(var(--size-0-75) * -1);
                margin-left: var(--negative-offset);
            }
        `,
    ];

    @state()
    _page = 1;
    @state()
    _order: SortOrder | null = null;
    @state()
    _field: string | null = null;
    @state()
    _total = 0;

    headerItems = defineHeaderItems({
        Id: { sortField: null, columnSpan: 1 },
        Name: { sortField: null, columnSpan: 2 },
        Customer: { sortField: null, columnSpan: 2 },
        Status: { sortField: null, columnSpan: 2 },
        Created: { sortField: 'CreatedUtc', columnSpan: 2 },
        Type: { sortField: null, columnSpan: 1 },
        Assigned: { sortField: null, columnSpan: 1 },
    });

    private _task = new Task(this, {
        task: async ([page, field, order]) => {
            const query = this.buildQuery({ page, field, order });
            const url = `report-filings?${query}`;
            const response = await this.client.get(url);
            const data = (await response.json()) as ReportFilingsResponse;
            this._total = data.total;

            return data;
        },
        args: () => [this._page, this._field, this._order] as const,
    });

    buildQuery(payload: QueryPayload): string {
        const { field, order, page } = payload;

        const searchParams = new URLSearchParams();
        searchParams.append('page', JSON.stringify({ no: page }));

        if ([field, order].every((e) => e)) {
            searchParams.append('sort', JSON.stringify({ field: field, order }));
        }

        return searchParams.toString();
    }

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    private _sortHandler(event: CustomEvent) {
        this._field = event.detail.field;
        this._order = event.detail.order;
        event.stopPropagation();
    }

    renderItem(item: ReportFiling): TemplateResult {
        const { partyFirstName: firstName, partyLastName: lastName } = item;
        return html`
            <tr>
                <td><strong>${item.reportFilingId}</strong></td>
                <td>
                    <a data-link="navigate" href="report-filing/${item.reportFilingId}">${item.filingName}</a>
                </td>
                <td>${nameFormatter({ firstName, lastName, userId: '' })}</td>
                <td>
                    <pli-status-label variant="${item.status}">${item.status}</pli-status-label>
                </td>
                <td>${getFormattedLocalTime(item.createdUtc)}</td>
                <td>${item.reportCode}</td>
                <td>
                    <pli-user-bubble-list .items="${item.assigned}"></pli-user-bubble-list>
                </td>
            </tr>
        `;
    }

    renderResult(data: ReportFilingsResponse) {
        const { isPaginationEnabled } = useTotalPagesCount(this._total);
        const hasItems = Boolean(data.list.length);
        return html`
            <div class="grid-vertical gap-2">
                ${when(
                    hasItems,
                    () =>
                        html` <pli-table
                            @sort="${this._sortHandler}"
                            .headerItems="${this.headerItems}"
                            .items="${data.list}"
                            .renderTemplate="${this.renderItem}"
                            sortOrderKey="report-filings-list-order"
                        >
                        </pli-table>`,
                    () =>
                        html` <div class="grid gap-2 items-center">
                            <div class="col-span-3 col-lg-span-2 col-start-4 col-lg-start-4">
                                <pli-illustration variant="no-reports"></pli-illustration>
                            </div>
                            <div class="col-span-3">
                                <div class="grid-vertical gap-1">
                                    <div class="grid-vertical gap-05">
                                        <pli-text as="h2" variant="h4">You don't have any reports yet</pli-text>
                                        <pli-text
                                            >You can create a report from an active case. It will then be displayed
                                            here</pli-text
                                        >
                                    </div>
                                    <pli-button class="to-case-button" variant="text" as="a" href="/cases"
                                        >Go to cases -></pli-button
                                    >
                                </div>
                            </div>
                        </div>`,
                )}
                ${isPaginationEnabled
                    ? html`
                          <pli-pagination
                              @pagination-changed="${this._paginationHandler}"
                              page="${this._page}"
                              total="${this._total}"
                          ></pli-pagination>
                      `
                    : null}
            </div>
        `;
    }

    render() {
        return html`
            <div class="grid-vertical gap-1">
                <pli-card>
                    <div class="grid">
                        <div class="col-span-8">
                            <pli-text variant="h2" as="h2" slot="title">Reports</pli-text>
                        </div>
                    </div>
                    ${this._task.render({
                        pending: () =>
                            html`<pli-table
                                .headerItems="${this.headerItems}"
                                .loading="${true}"
                                loadingRowsCount="${1}"
                            ></pli-table>`,
                        complete: (data) => this.renderResult(data),
                    })}
                </pli-card>
            </div>
        `;
    }
}
